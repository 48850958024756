define("discourse/plugins/chat/discourse/components/toggle-channel-membership-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/plugin-outlet", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _dButton, _pluginOutlet, _concatClass, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ToggleChannelMembershipButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    options = {};
    constructor() {
      super(...arguments);
      this.options = {
        labelType: "normal",
        joinTitle: (0, _discourseI18n.i18n)("chat.channel_settings.join_channel"),
        joinIcon: "",
        joinClass: "",
        leaveTitle: (0, _discourseI18n.i18n)("chat.channel_settings.leave_channel"),
        leaveIcon: "",
        leaveClass: "",
        ...this.args.options
      };
    }
    get label() {
      if (this.options.labelType === "none") {
        return "";
      }
      if (this.options.labelType === "short") {
        if (this.args.channel.currentUserMembership.following) {
          return (0, _discourseI18n.i18n)("chat.channel_settings.leave");
        } else {
          return (0, _discourseI18n.i18n)("chat.channel_settings.join");
        }
      }
      if (this.args.channel.currentUserMembership.following) {
        return (0, _discourseI18n.i18n)("chat.channel_settings.leave_channel");
      } else {
        return (0, _discourseI18n.i18n)("chat.channel_settings.join_channel");
      }
    }
    onJoinChannel() {
      this.isLoading = true;
      return this.chat.followChannel(this.args.channel).then(() => {
        this.args.onJoin?.(this.args.channel);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isLoading = false;
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "onJoinChannel", [_object.action]))();
    async onLeaveChannel() {
      this.isLoading = true;
      try {
        if (this.args.channel.chatable.group) {
          await this.chatApi.leaveChannel(this.args.channel.id);
        } else {
          await this.chat.unfollowChannel(this.args.channel);
        }
        this.args.onLeave?.(this.args.channel);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.isLoading = false;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "onLeaveChannel", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @channel.currentUserMembership.following}}
          <DButton
            @action={{this.onLeaveChannel}}
            @translatedLabel={{this.label}}
            @translatedTitle={{this.options.leaveTitle}}
            @icon={{this.options.leaveIcon}}
            @disabled={{this.isLoading}}
            class={{concatClass
              "toggle-channel-membership-button -leave"
              this.options.leaveClass
            }}
          />
        {{else}}
          <PluginOutlet
            @name="chat-join-channel-button"
            @outletArgs={{hash
              onJoinChannel=this.onJoinChannel
              channel=@channel
              icon=this.options.joinIcon
              title=this.options.joinTitle
              label=this.label
              disabled=this.isLoading
            }}
            @defaultGlimmer={{true}}
          >
            <DButton
              @action={{this.onJoinChannel}}
              @translatedLabel={{this.label}}
              @translatedTitle={{this.options.joinTitle}}
              @icon={{this.options.joinIcon}}
              @disabled={{this.isLoading}}
              class={{concatClass
                "toggle-channel-membership-button -join"
                this.options.joinClass
              }}
            />
          </PluginOutlet>
        {{/if}}
      
    */
    {
      "id": "C+/hHDZi",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"currentUserMembership\",\"following\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"toggle-channel-membership-button -leave\",[30,0,[\"options\",\"leaveClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onLeaveChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"leaveTitle\"]],[30,0,[\"options\",\"leaveIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,2],null,[[\"@name\",\"@outletArgs\",\"@defaultGlimmer\"],[\"chat-join-channel-button\",[28,[32,3],null,[[\"onJoinChannel\",\"channel\",\"icon\",\"title\",\"label\",\"disabled\"],[[30,0,[\"onJoinChannel\"]],[30,1],[30,0,[\"options\",\"joinIcon\"]],[30,0,[\"options\",\"joinTitle\"]],[30,0,[\"label\"]],[30,0,[\"isLoading\"]]]]],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,0],[[16,0,[28,[32,1],[\"toggle-channel-membership-button -join\",[30,0,[\"options\",\"joinClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onJoinChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"joinTitle\"]],[30,0,[\"options\",\"joinIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/toggle-channel-membership-button.js",
      "scope": () => [_dButton.default, _concatClass.default, _pluginOutlet.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ToggleChannelMembershipButton;
});