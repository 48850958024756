define("discourse/plugins/chat/discourse/connectors/user-preferences-nav/chat-preferences", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatPreferences extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model
      } = _ref;
      let {
        siteSettings,
        currentUser
      } = _ref2;
      return siteSettings.chat_enabled && (model.can_chat || currentUser?.admin);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="user-nav__preferences-chat">
          <LinkTo @route="preferences.chat">
            {{dIcon "d-chat"}}
            <span>{{i18n "chat.title_capitalized"}}</span>
          </LinkTo>
        </li>
      
    */
    {
      "id": "dTsHPy7W",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"user-nav__preferences-chat\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@route\"],[\"preferences.chat\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"d-chat\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,2],[\"chat.title_capitalized\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/connectors/user-preferences-nav/chat-preferences.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatPreferences;
});