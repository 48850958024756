define("discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _template, _dButton, _dModal, _ajaxError, _discourseI18n, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalToggleChannelStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get channel() {
      return this.args.model;
    }
    get buttonLabel() {
      if (this.channel?.isClosed) {
        return "chat.channel_settings.open_channel";
      } else {
        return "chat.channel_settings.close_channel";
      }
    }
    get instructions() {
      if (this.channel?.isClosed) {
        return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.channel_open.instructions"));
      } else {
        return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.channel_close.instructions"));
      }
    }
    get modalTitle() {
      if (this.channel?.isClosed) {
        return "chat.channel_open.title";
      } else {
        return "chat.channel_close.title";
      }
    }
    onStatusChange() {
      const status = this.channel.isClosed ? _chatChannel.CHANNEL_STATUSES.open : _chatChannel.CHANNEL_STATUSES.closed;
      return this.chatApi.updateChannelStatus(this.channel.id, status).then(() => {
        this.args.closeModal();
        this.router.transitionTo("chat.channel", ...this.channel.routeModels);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onStatusChange", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-toggle-channel-status"
          @inline={{@inline}}
          @title={{i18n this.modalTitle}}
        >
          <:body>
            <p
              class="chat-modal-toggle-channel-status__instructions"
            >{{this.instructions}}</p>
          </:body>
          <:footer>
            <DButton
              @action={{this.onStatusChange}}
              @label={{this.buttonLabel}}
              id="chat-channel-toggle-btn"
              class="btn-primary"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "u7Y8e/1+",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-toggle-channel-status\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[32,1],[[30,0,[\"modalTitle\"]]],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-toggle-channel-status__instructions\"],[12],[1,[30,0,[\"instructions\"]]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,1,\"chat-channel-toggle-btn\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"onStatusChange\"]],[30,0,[\"buttonLabel\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalToggleChannelStatus;
});