define("discourse/plugins/chat/discourse/components/user-threads/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/routing", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/channel-icon", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/chat/list", "discourse/plugins/chat/discourse/components/chat-message-thread-indicator", "discourse/plugins/chat/discourse/components/thread-title", "discourse/plugins/chat/discourse/components/user-threads/preview", "discourse/plugins/chat/discourse/models/chat-thread-preview", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _routing, _service, _decorators, _discourseI18n, _channelIcon, _channelTitle, _list, _chatMessageThreadIndicator, _threadTitle, _preview, _chatThreadPreview, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserThreads extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    trackedChannels = {};
    willDestroy() {
      super.willDestroy(...arguments);
      Object.keys(this.trackedChannels).forEach(id => {
        this.messageBus.unsubscribe(`/chat/${id}`, this.onMessage);
      });
      this.trackedChannels = {};
    }
    get threadsCollection() {
      return this.chatApi.userThreads(this.handleLoadedThreads);
    }
    static #_6 = (() => dt7948.n(this.prototype, "threadsCollection", [_tracking.cached]))();
    handleLoadedThreads(result) {
      return result.threads.map(threadObject => {
        const channel = this.chatChannelsManager.store(threadObject.channel);
        const thread = channel.threadsManager.add(channel, threadObject);
        const tracking = result.tracking[thread.id];
        if (tracking) {
          thread.tracking.mentionCount = tracking.mention_count;
          thread.tracking.unreadCount = tracking.unread_count;
          thread.tracking.watchedThreadsUnreadCount = tracking.watched_threads_unread_count;
        }
        this.trackChannel(thread.channel);
        return thread;
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "handleLoadedThreads", [_decorators.bind]))();
    trackChannel(channel) {
      if (this.trackedChannels[channel.id]) {
        return;
      }
      this.trackedChannels[channel.id] = channel;
      this.messageBus.subscribe(`/chat/${channel.id}`, this.onMessage, channel.channelMessageBusLastId);
    }
    onMessage(data) {
      if (data.type === "update_thread_original_message") {
        const channel = this.trackedChannels[data.channel_id];
        if (!channel) {
          return;
        }
        const thread = channel.threadsManager.threads.find(t => t.id === data.thread_id);
        if (thread) {
          thread.preview = _chatThreadPreview.default.create(data.preview);
        }
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <List
          @collection={{this.threadsCollection}}
          class="c-user-threads"
          as |list|
        >
          <list.Item as |thread|>
            <div class="c-user-thread" data-id={{thread.id}}>
              {{#if this.site.mobileView}}
                <ChannelIcon @thread={{thread}} />
              {{/if}}
    
              {{#if this.site.mobileView}}
                <LinkTo
                  class="c-user-thread__link"
                  @route="chat.channel.thread"
                  @models={{thread.routeModels}}
                >
                  <ChannelTitle @channel={{thread.channel}} />
                  <ThreadTitle @thread={{thread}} />
    
                  <ThreadPreview @preview={{thread.preview}} />
                </LinkTo>
              {{else}}
                <ChannelTitle @channel={{thread.channel}} />
                <ThreadTitle @thread={{thread}} />
    
                <ThreadIndicator
                  @message={{thread.originalMessage}}
                  @interactiveUser={{false}}
                  @interactiveThread={{false}}
                  tabindex="-1"
                />
              {{/if}}
            </div>
          </list.Item>
          <list.EmptyState>
            <div class="empty-state-threads">
              {{i18n "chat.empty_state.my_threads"}}
            </div>
          </list.EmptyState>
        </List>
      
    */
    {
      "id": "t2ylis3C",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"c-user-threads\"]],[[\"@collection\"],[[30,0,[\"threadsCollection\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"c-user-thread\"],[15,\"data-id\",[30,2,[\"id\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"            \"],[8,[32,1],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"            \"],[8,[32,2],[[24,0,\"c-user-thread__link\"]],[[\"@route\",\"@models\"],[\"chat.channel.thread\",[30,2,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[32,3],null,[[\"@channel\"],[[30,2,[\"channel\"]]]],null],[1,\"\\n              \"],[8,[32,4],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\\n              \"],[8,[32,5],null,[[\"@preview\"],[[30,2,[\"preview\"]]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[32,3],null,[[\"@channel\"],[[30,2,[\"channel\"]]]],null],[1,\"\\n            \"],[8,[32,4],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n\\n            \"],[8,[32,6],[[24,\"tabindex\",\"-1\"]],[[\"@message\",\"@interactiveUser\",\"@interactiveThread\"],[[30,2,[\"originalMessage\"]],false,false]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n      \"],[8,[30,1,[\"EmptyState\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"empty-state-threads\"],[12],[1,\"\\n          \"],[1,[28,[32,7],[\"chat.empty_state.my_threads\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"list\",\"thread\"],false,[\"if\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/index.js",
      "scope": () => [_list.default, _channelIcon.default, _routing.LinkTo, _channelTitle.default, _threadTitle.default, _preview.default, _chatMessageThreadIndicator.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserThreads;
});