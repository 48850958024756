define("discourse/plugins/chat/discourse/components/chat-message/blocks/block", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat-message/blocks/actions", "@ember/component", "@ember/template-factory"], function (_exports, _component, _actions, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Block extends _component.default {
    get blockForType() {
      switch (this.args.definition.type) {
        case "actions":
          return _actions.default;
        default:
          throw new Error(`Unknown block type: ${this.args.definition.type}`);
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message__block-wrapper">
          <div class="chat-message__block">
            <this.blockForType
              @createInteraction={{@createInteraction}}
              @definition={{@definition}}
            />
          </div>
        </div>
      
    */
    {
      "id": "s8osS7zd",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message__block-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message__block\"],[12],[1,\"\\n        \"],[8,[30,0,[\"blockForType\"]],null,[[\"@createInteraction\",\"@definition\"],[[30,1],[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@createInteraction\",\"@definition\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message/blocks/block.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Block;
});