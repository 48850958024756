define("discourse/plugins/chat/discourse/components/chat/navbar/sub-title", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatNavbarSubTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="c-navbar__sub-title">
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@title}}
      {{/if}}
    </div>
  
  */
  {
    "id": "yKEEkYDG",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"c-navbar__sub-title\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@title\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/sub-title.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "sub-title:ChatNavbarSubTitle"));
  var _default = _exports.default = ChatNavbarSubTitle;
});