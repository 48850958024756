define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _didInsert, _didUpdate, _service, _navbar, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    get backBtnRoute() {
      if (this.chatHistory.previousRoute?.name === "chat.browse") {
        return "chat.browse";
      } else if (this.chat.activeChannel?.isDirectMessageChannel) {
        return "chat.direct-messages";
      } else {
        return "chat.channels";
      }
    }
    fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      return this.chatChannelsManager.find(this.args.params.channelId).then(channel => {
        this.chat.activeChannel = channel;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "fetchChannel", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --channel">
          <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
            <navbar.BackButton @route={{this.backBtnRoute}} />
            <navbar.ChannelTitle @channel={{this.chat.activeChannel}} />
            <navbar.Actions as |a|>
              <a.ThreadsListButton @channel={{this.chat.activeChannel}} />
              <a.ToggleDrawerButton />
              <a.FullPageButton />
              <a.CloseDrawerButton />
            </navbar.Actions>
          </Navbar>
    
          {{#if this.chatStateManager.isDrawerExpanded}}
            <div
              class="chat-drawer-content"
              {{didInsert this.fetchChannel}}
              {{didUpdate this.fetchChannel @params.channelId}}
            >
              {{#if this.chat.activeChannel}}
                {{#each (array this.chat.activeChannel) as |channel|}}
                  {{#if channel}}
                    <ChatChannel
                      @targetMessageId={{readonly @params.messageId}}
                      @channel={{channel}}
                    />
                  {{/if}}
                {{/each}}
              {{/if}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "qj+24oN2",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --channel\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\"],[[30,0,[\"backBtnRoute\"]]]],null],[1,\"\\n        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"        \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannel\"]]],null],[4,[32,2],[[30,0,[\"fetchChannel\"]],[30,3,[\"channelId\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[42,[28,[31,2],[[28,[31,2],[[28,[32,3],[[30,0,[\"chat\",\"activeChannel\"]]],null]],null]],null],null,[[[41,[30,4],[[[1,\"                \"],[8,[32,4],null,[[\"@targetMessageId\",\"@channel\"],[[28,[31,3],[[30,3,[\"messageId\"]]],null],[30,4]]],null],[1,\"\\n\"]],[]],null]],[4]],null]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\",\"@params\",\"channel\"],false,[\"if\",\"each\",\"-track-array\",\"readonly\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel.js",
      "scope": () => [_navbar.default, _didInsert.default, _didUpdate.default, _helper.array, _chatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannel;
});