define("discourse/plugins/chat/discourse/components/chat-user-display-name", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/concat-class", "discourse/lib/utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _concatClass, _utilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatUserDisplayName extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get shouldPrioritizeNameInUx() {
      return !this.siteSettings.prioritize_username_in_ux;
    }
    get hasValidName() {
      return this.args.user?.name && this.args.user.name.trim().length > 0;
    }
    get formattedUsername() {
      return (0, _utilities.formatUsername)(this.args.user?.username);
    }
    get shouldShowNameFirst() {
      return this.shouldPrioritizeNameInUx && this.hasValidName;
    }
    get shouldShowNameLast() {
      return !this.shouldPrioritizeNameInUx && this.hasValidName;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="chat-user-display-name">
          {{#if this.shouldShowNameFirst}}
            <span class="chat-user-display-name__name -first">{{@user.name}}</span>
          {{/if}}
    
          <span
            class={{concatClass
              "chat-user-display-name__username"
              (unless this.shouldShowNameFirst "-first")
            }}
          >
            {{this.formattedUsername}}
          </span>
    
          {{#if this.shouldShowNameLast}}
            <span class="chat-user-display-name__name">{{@user.name}}</span>
          {{/if}}
        </span>
      
    */
    {
      "id": "fcayL8W1",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-user-display-name\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldShowNameFirst\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-display-name__name -first\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,1],[15,0,[28,[32,0],[\"chat-user-display-name__username\",[52,[51,[30,0,[\"shouldShowNameFirst\"]]],\"-first\"]],null]],[12],[1,\"\\n        \"],[1,[30,0,[\"formattedUsername\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"shouldShowNameLast\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-display-name__name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-display-name.js",
      "scope": () => [_concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatUserDisplayName;
});