define("discourse/plugins/chat/discourse/components/chat-message/blocks/element", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat-message/blocks/elements/button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _button, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Element extends _component.default {
    get elementForType() {
      switch (this.args.definition.type) {
        case "button":
          return _button.default;
        default:
          throw new Error(`Unknown element type: ${this.args.definition.type}`);
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <this.elementForType
          @createInteraction={{@createInteraction}}
          @definition={{@definition}}
        />
      
    */
    {
      "id": "s1sxz/ik",
      "block": "[[[1,\"\\n    \"],[8,[30,0,[\"elementForType\"]],null,[[\"@createInteraction\",\"@definition\"],[[30,1],[30,2]]],null],[1,\"\\n  \"]],[\"@createInteraction\",\"@definition\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message/blocks/element.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Element;
});